<template>
    <NavTopBar />
    <div id="pay">
        <div class="top_info">
            <div class="top_info_header">
                <img :src="configInfo.main_site_logo" :onerror='defaultImg' alt />
                <div class="top_info_progress flex_row_center_center">

                    <div class="progress_item">
                        <div class="progress flex_row_center_center">
                            <span class="active">{{ 1 }}</span>
                            <div class="progress_line">
                                <div :class="{ content: true, active: true, current: false }"></div>
                            </div>
                        </div>
                        <p>确认订单</p>
                    </div>

                    <div class="progress_item">
                        <div class="progress flex_row_center_center">
                            <span class="active">{{ 2 }}</span>
                            <div class="progress_line">
                                <div :class="{ content: true, active: payComplete }"></div>
                            </div>
                        </div>
                        <p>付款</p>
                    </div>

                    <div class="progress_item">
                        <div class="progress flex_row_start_center">
                            <span :class="{ active: payComplete }">{{ 3 }}</span>
                        </div>
                        <p>支付成功</p>
                    </div>

                </div>
            </div>
            <div class="top_info_text flex_row_between_between">
                <span>订单提交成功，请您尽快付款！ 订单号： {{ pay_info.data.paySn }}</span>
                <div class="price">
                    应付金额
                    <span>{{ pay_info.data.needPay }}</span> 元
                </div>
            </div>
            <div class="top_info_text flex_row_between_between">
                <span>
                    请您在提交订单后
                    <span>24小时内</span>完成支付，否则订单会自动取消
                </span>
                <div class="show_detail pointer" @click="showDetail">{{ show_detail ? '收起详情' : '展开详情' }}</div>
            </div>
            <div class="receive_info">
                <p v-if="show_detail && pay_info.data.receiveAddress && pay_info.data.receiverName">
                    收货地址：{{ pay_info.data.receiveAddress }}
                    收货人：{{ pay_info.data.receiverName }}
                    {{ pay_info.data.receiverMobile }}</p>
                <p v-if="show_detail">商品名称：{{ buy_name_list }}</p>
            </div>
        </div>
        <div class="bg">
            <div class="pay_method" v-if="hasPayMethod">
                <div v-if="!show_wx_pay && !show_alipay">
                    <div class="balance" v-if="balance_list.data.length > 0">
                        <img class="logo" :src="balance" alt />
                        <div class="balance_info">
                            <i :class="{ iconfont: true, 'icon-yuanquan1': accountInfo.balance >= pay_info.data.needPay, 'icon-duihao1': current_pay_method.data.payMethod == balance_list.data[0].payMethod, 'icon-jinzhi1': accountInfo.balance < pay_info.data.needPay }"
                                @click="accountInfo.balance >= pay_info.data.needPay ? changePayMethod(balance_list.data[0]) : ''"></i>
                            <!-- <i class="iconfont i"></i> -->
                            <span class="weight big_size">使用余额支付</span>
                            <span class="avai">
                                （可用余额：
                                <span class="weight">{{ accountInfo.balance }}</span>元，目前需要在线支付：
                                <span class="weight">{{ pay_info.data.needPay }}</span>元）余额不足？
                                <span class="weight pointer" @click="goRecharge" style="color:#168ED8">马上充值</span>
                            </span>
                        </div>

                    </div>
                    <div class="balance other_pay_method" v-if="other_pay_list.data.length > 0">
                        <img class="logo" :src="otherPay" alt />
                        <div class="balance_info flex">
                            <div :class="{ other_pay_item: true, flex_row_around_center: true, wechat: index != 0 }"
                                v-for="(item, index) in other_pay_list.data" :key="index"
                                @click="changePayMethod(item)">
                                <i
                                    :class="{ iconfont: true, 'icon-yuanquan1': current_pay_method.data.payMethod != item.payMethod, 'icon-duihao1': current_pay_method.data.payMethod == item.payMethod }"></i>
                                <img :src="item.payMethod == 'tl_alipay' ? ali : item.payMethod == 'tl_wx' ? wechat : banks"
                                    alt />
                                <span>{{ item.payMethodName }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="pay" @click="pay" style="cursor: pointer;">立即支付</div>
                </div>
                <div class="wx_pay_con" v-if="show_wx_pay">
                    <p class="title" @click="hideWxPay">
                        <i class="iconfont icon-ziyuan2"></i>
                        <span>选择其它支付方式</span>
                    </p>
                    <div class="qr flex_row_around_center">
                        <div class="left flex_column_start_center">
                            <div id="wx_img" class="img"></div>
                            <p>
                                使用
                                <span>微信支付</span>APP
                            </p>
                            <p>扫码完成支付</p>
                            <div class="refresh" @click="refreshWxQrcode">刷新</div>
                        </div>
                        <img class="wx_png" :src="wx_pay_tip" />
                    </div>
                </div>

                <div class="wx_pay_con" v-if="show_alipay">
                    <p class="title" @click="hideWxPay">
                        <i class="iconfont icon-ziyuan2"></i>
                        <span>选择其它支付方式</span>
                    </p>
                    <div class="qr flex_row_around_center">
                        <div class="left flex_column_start_center">
                            <div id="ali_img" class="img"></div>
                            <p>
                                使用
                                <span>支付宝支付</span>APP
                            </p>
                            <p>扫码完成支付</p>
                            <div class="refresh" @click="refreshWxQrcode">刷新</div>
                        </div>
                        <img class="wx_png" :src="wx_pay_tip" />
                    </div>
                </div>
            </div>
            <div class="no_payMethod" v-else>
                <img src="@/assets/member/member_empty.png" alt="">
                <p>暂无可用的支付方式，平台正在紧急处理中～</p>
            </div>
        </div>

        <el-dialog title="正在使用余额支付" width="450px" v-model="pdVisible" @close="close_pd" top="30vh">
            <div class="dialog_desc2">短信验证码已发送至
                <span>{{ $replaceConByPosition(memberInfo.memberMobile, 4, 6, '****') }}</span>
            </div>
            <div class="flex_row_center_center line1">

                <span class="sms_text">短信验证码</span>

                <input type="password" v-model="smsCode" class="dialog_input" />

                <div class="sms_code" @click="sendSmsCode">{{ countDown ? `${countDown}s` : '获取验证码' }}</div>

            </div>
            <div class="dialog_btn flex_row_end_center">
                <div class="dialog_set" @click="balancePay">立即付款</div>
            </div>
        </el-dialog>



    </div>
</template>

<script>
import { reactive, getCurrentInstance, ref, onMounted, onUnmounted } from "vue";
import { ElMessage, ElInput } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { qrcanvas } from 'qrcanvas'
export default {
    name: "Pay",
    components: {
        ElInput
    },
    setup() {
        const balance = require("../../../assets/buy/balance.png");
        const otherPay = require("../../../assets/buy/otherPay.png");
        const ali = require("../../../assets/buy/ali.png");
        const wechat = require("../../../assets/buy/wechat.png");
        const wx_pay_tip = require("../../../assets/buy/wx_pay_tip.png");
        const banks = require('@/assets/bank_card.png')

        const route = useRoute();
        const router = useRouter();
        const store = useStore()
        const { proxy } = getCurrentInstance();
        const pay_method = reactive({ data: [] });
        const pay_info = reactive({ data: {} });
        const paySn = ref("");
        const current_pay_method = reactive({ data: {} });
        const balance_list = reactive({ data: [] });
        const other_pay_list = reactive({ data: [] });
        const buy_name_list = ref("");
        const password = ref("");
        const show_wx_pay = ref(false);
        const show_alipay = ref(false)
        const wxPayQrImg = ref('');//微信支付二维码
        const timer = ref(0);//定时器
        const show_detail = ref(true)
        const hasPayMethod = ref(true)
        const configInfo = ref(store.state.configInfo)
        const memberInfo = ref(store.state.memberInfo);
        const defaultImg = ref('this.src="' + require('@/assets/common_top_logo.png') + '"')
        const accountInfo = ref({})
        const pdVisible = ref(false)
        const smsCode = ref('')
        const countDown = ref(0)
        const payData = ref(0)
        const isPreSend = ref(false)


        //获取支付方式
        const getPayMethod = () => {
            proxy
                .$get("v3/system/common/payMethod", {
                    source: "pc",
                    type: 1
                })
                .then(res => {
                    if (res.state == 200) {
                        hasPayMethod.value = res.data.length > 0 ? true : false
                        res.data.map(item => {
                            if (item.payMethod == "tl_bal") {
                                balance_list.data.push(item);
                            } else {
                                other_pay_list.data.push(item);
                            }
                        });
                    } else {
                        ElMessage(res.msg);
                    }
                })
        };
        //获取订单支付数据
        const getPayInfo = () => {
            proxy
                .$get("v3/integral/front/integral/orderPay/payInfo", {
                    paySn: route.query.paySn,
                    payFrom: route.query.payFrom
                })
                .then(res => {
                    if (res.state == 200) {
                        pay_info.data = res.data;
                        buy_name_list.value = res.data.goodsNameList.join(" + ");
                    } else if (res.state == 267) {
                        ElMessage.success(res.msg + ',2s后自动跳转订单列表');
                        //清除定时器
                        if (timer.value) {
                            clearInterval(timer.value);
                            timer.value = 0;
                        }
                        setTimeout(() => {
                            router.replace({
                                path: '/member/pointOrder/list',
                            });
                        }, 2000)
                    } else {
                        ElMessage(res.msg);
                    }
                })

        };
        const changePayMethod = pay_method => {
            isPrePay.value = false

            current_pay_method.data = pay_method;
        };
        //去支付
        const payComplete = ref(false)
        const isPrePay = ref(false)

        const pay = () => {
            if (!current_pay_method.data.payMethod) {
                ElMessage.warning("请选择支付方式");
                return;
            }
            let param = {};
            param.payType = current_pay_method.data.payType;
            param.payMethod = current_pay_method.data.payMethod;
            param.paySn = pay_info.data.paySn;

            if (isPrePay.value) {
                return
            }
            isPrePay.value = true
            //请求支付接口
            proxy.$post("v3/integral/front/integral/orderPay/doPay", param).then(res => {
                if (res.state == 200) {
                    //余额支付
                    if (param.payMethod == 'tl_bal') {
                        payData.value = res.data.payData
                        pdVisible.value = true
                        countDown.value = 61
                        toCountDown()
                        return
                    } else if (param.payMethod == 'tl_alipay') {
                        show_alipay.value = true
                        let canvas = qrcanvas({
                            data: res.data.payData, //二维码内容
                            size: 230,
                            colorDark: "red",
                        });
                        setTimeout(() => {
                            document.getElementById("ali_img").innerHTML = "";
                            document.getElementById("ali_img").appendChild(canvas);
                        }, 10)

                        // 定时查询是否支付成功
                        timer.value = setInterval(() => {
                            queryPayState();
                        }, 3000)
                        //两小时自动跳转订单列表
                        setTimeout(() => {
                            router.replace({
                                path: '/member/pointOrder/list',
                            });
                        }, 432000000);

                    } else if (param.payMethod == 'tl_wx') {
                        show_wx_pay.value = true;
                        let canvas = qrcanvas({
                            data: res.data.payData, //二维码内容
                            size: 230,
                            colorDark: "red",
                        });
                        setTimeout(() => {
                            document.getElementById("wx_img").innerHTML = "";
                            document.getElementById("wx_img").appendChild(canvas);
                        }, 10)

                        // 定时查询是否支付成功
                        timer.value = setInterval(() => {
                            queryPayState();
                        }, 3000)
                        //两小时自动跳转订单列表
                        setTimeout(() => {
                            router.replace({
                                path: '/member/pointOrder/list',
                            });
                        }, 432000000);
                    } else {
                        window.location.href = res.data.payData
                    }
                } else {
                    ElMessage(res.msg);
                }
            })
        };

        const close_pd = (done) => {
            timer.value = null
            countDown.value = 0
            clearTimeout(timer.value)
            isPrePay.value = false
        }



        // 定时查询是否支付成功
        const queryPayState = () => {
            getPayInfo();
        }
        //隐藏微信支付内容
        const hideWxPay = () => {
            show_wx_pay.value = false;
            show_alipay.value = false
        };
        //刷新微信支付二维码
        const refreshWxQrcode = () => {
            pay();
        };
        //收起展开详情
        const showDetail = () => {
            show_detail.value = !show_detail.value
        }
        const getTlAccount = () => {
            proxy.$get('v3/member/front/withdrawal/accountInfo').then(res => {
                if (res.state == 200) {
                    accountInfo.value = Object.assign(accountInfo.value, res.data)
                }
            })
        }

        const getTlDetail = () => [
            proxy.$get('v3/member/front/openAccount/detail').then(res => {
                if (res.state == 200) {
                    accountInfo.value = Object.assign(accountInfo.value, res.data)
                }
            })
        ]

        const toCountDown = () => {
            countDown.value--
            if (countDown.value <= 0) {
                isPreSend.value = false
                clearTimeout(timer.value)
            } else {
                timer.value = setTimeout(toCountDown, 1000)
            }
        }

        const balancePay = () => {
            proxy.$post('v3/business/front/orderPay/tlBalancePay', {
                bizOrderNo: payData.value,
                verificationCode: smsCode.value
            }).then(res => {
                if (res.state == 200) {
                    ElMessage.success('支付成功')
                    setTimeout(() => {
                        router.replace({
                            path: '/member/pointOrder/list',
                        });
                    }, 2000)
                } else {
                    ElMessage(res.msg)
                }
            })
        }

        const sendSmsCode = () => {


            if (isPreSend.value) {
                return
            }
            isPreSend.value = true

            if (countDown.value > 0) {
                return
            }

            proxy.$get('v3/business/front/orderPay/resendVerify', {
                bizOrderNo: payData.value
            }).then(res => {
                if (res.state == 200) {
                    countDown.value = 60
                    toCountDown()
                } else {
                    isPreSend.value = false
                }
            })

        }


        onMounted(() => {
            paySn.value = route.query.paySn;
            getPayMethod();
            getPayInfo();
            getTlAccount()
            getTlDetail()
        });
        const goRecharge = () => {
            router.push('/member/recharge')
        }
        const forgetPassword = () => {
            router.push('/member/pwd/reset')
        }
        onUnmounted(() => {
            if (timer.value) {
                clearInterval(timer.value);
                timer.value = 0;
            }
        });
        return {
            pay_method,
            balance,
            otherPay,
            ali,
            wechat,
            pay_info,
            current_pay_method,
            balance_list,
            other_pay_list,
            changePayMethod,
            buy_name_list,
            password,
            pay,
            wx_pay_tip,
            show_wx_pay,
            hideWxPay,
            refreshWxQrcode,
            wxPayQrImg,
            goRecharge,
            forgetPassword,
            showDetail,
            show_detail,
            hasPayMethod,
            configInfo,
            defaultImg,
            memberInfo,
            payComplete,
            banks,
            accountInfo,
            pdVisible,
            countDown,
            smsCode,
            balancePay,
            sendSmsCode,
            show_alipay,
            close_pd
        };
    },

    beforeRouteEnter(to, from, next) {
        // ...
        if (from.name == 'Login') {
            next('/index')
        } else {
            next()
        }
    }
};
</script>

<style lang="scss">
@import "../../../style/pay.scss";

.top_info_header {
    img {
        width: 135px;
        height: 98px;
        object-fit: contain;
    }
}

.no_payMethod {
    margin: 0 auto;
    width: 1200px;
    height: 560px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 163px;
        height: 114px;
    }

    p {
        margin-top: 39px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
    }
}

.dialog_desc,
.dialog_desc2 {
    line-height: 28px;
    color: #121212;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
    margin-top: 35px;
    padding-left: 50px;
    padding-right: 50px;
}

.dialog_desc2 {
    margin-top: 25px;

    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #121212;
}

.dialog_input {
    display: block;

    height: 42px;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    padding: 5px 10px;
}

.dialog_btn {
    padding: 15px 20px;
    border-top: 1px solid #EEEEEE;

    .dialog_cancle,
    .dialog_set {
        width: 96px;
        height: 34px;
        line-height: 34px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        text-align: center;
        background: #E73439;
        border-radius: 4px;
        cursor: pointer;
    }

    .dialog_cancle {
        color: #666262;
        background: #E3E3E3;
    }

    .dialog_set {
        color: #FFFFFF;
        background: #E73439;
        margin-left: 15px;
    }
}

.el-dialog__body {
    padding: 0;
}

.sms_text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-right: 15px;
}

.sms_code {
    width: 98px;
    height: 42px;
    border: 1px solid #BFBFBF;
    border-radius: 4px;
    text-align: center;
    line-height: 42px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-left: 15px;
    cursor: pointer;
}


.line1 {
    margin: 45px 0;
}
</style>